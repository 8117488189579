<template>
  <div class="location-statistics" ref="locationStatistics">
    <div class="function-buttons" ref="functionButtons" v-show="false">
      <el-button type="primary" size="small"></el-button>
    </div>
    <div class="form-area" ref="form">
      <analysisForm @getFormVal="onSearch">
        <el-button
          type="primary"
          size="small"
          :disabled="tableData.length === 0"
          :loading="downLoadStatus"
          @click="exportData"
          >导出</el-button
        >
      </analysisForm>
    </div>
    <el-table
      id="outputTable"
      :data="tableData"
      stripe
      :height="tableHeight"
      v-if="tableData.length > 0"
    >
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column
        prop="companyName"
        label="所属企业"
        width="150"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="cph"
        label="车牌号"
        :show-overflow-tooltip="true"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="licensePlateColor"
        label="车牌颜色"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">{{
          colorObj[Number(scope.row.licensePlateColor)]
        }}</template>
      </el-table-column>
      <el-table-column
        prop="type"
        label="车辆类型"
        :show-overflow-tooltip="true"
        width="140"
      >
        <template slot-scope="scope">{{
          typeObj[Number(scope.row.type)]
        }}</template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in tableData[0].dailyTimes"
        :key="index"
        :label="item.date"
        :width="tableData[0].dailyTimes.length >= 8 ? '120' : ''"
      >
        <template slot-scope="scope">{{
          scope.row.dailyTimes[index].val
        }}</template>
      </el-table-column>
      <el-table-column prop="total" label="合计"></el-table-column>
      <el-table-column
        prop="averageNum"
        label="每日平均"
        width="120"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="returnInterval"
        label="回传间隔（秒）"
        width="120"
        :show-overflow-tooltip="true"
      ></el-table-column>
    </el-table>
    <div ref="pagination">
      <el-pagination
        background
        v-if="tableData.length > 0"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import analysisForm from "@/components/analysisForm/analysisFormNew.vue";
// import analysisForm from "@/components/analysisForm/analysisFormDemo.vue";
import { queryLocationForm } from "@/api/lib/gps-api.js";
import { queryDictsByCodes } from "@/api/lib/api.js";
import { formatDict, getCurent, formatDate } from "@/common/utils/index";

import { addExcelExport } from "@/api/lib/refreshAlarm.js";

export default {
  name: "locationStatistics",
  components: {
    analysisForm,
  },
  data () {
    return {
      tableHeight: 0,
      tableData: [],
      form: {
        currentPage: 1,
        pageSize: 10,
      },
      typeObj: {},
      colorObj: {},
      total: 0,
      exportForm: {},
      downLoadStatus: false,
    };
  },
  methods: {
    // 计算表格高度
    computeHeight () {
      let wholeHeight = this.$refs.locationStatistics.clientHeight;
      let buttonsHeight = this.$refs.functionButtons.clientHeight;
      let formHeight = this.$refs.form.clientHeight;
      let paginationHeight = this.$refs.pagination.clientHeight;
      this.tableHeight =
        wholeHeight - 16 - buttonsHeight - 16 - formHeight - paginationHeight - 10 - 40;

    },
    //导出
    exportData () {
      this.downLoadStatus = true;
      delete this.exportForm.currentPage;
      delete this.exportForm.pageSize;
      let data = {
        baseUrl: "report",
        userId: sessionStorage.getItem("userId")||localStorage.getItem('userId'),
        createTime: formatDate(new Date()),
        fileName: `定位统计-${getCurent()}`,
        filePath: null,
        fileStatus: 1,
        generateTime: null,
        queryParam: JSON.stringify(this.exportForm),
        queryPath: "/vehicleStatistics/exportLocation",
      };
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.downLoadStatus = false;
          this.$store.dispatch("setRefreshStatus", true);
        })
        .catch(() => {
          this.downLoadStatus = false;
        });
    },
    // 点击查询
    onSearch (value) {
      this.form.vehicleNos = value.vehicleNos;
      this.form.companyId = value.companyId;
      this.form.beginTime = value.beginTime;
      this.form.endTime = value.endTime;
      this.form.currentPage = 1;
      this.form.pageSize = 10;
      this.getListByField();
    },
    //获取数据
    getListByField () {
      let data = { ...this.form };
      queryLocationForm(data).then((res) => {
        if (res.code === 1000) {
          this.tableData = res.data.list;
          this.total = res.data.total;
          if (res.data.list.length === 0 && data.currentPage !== 1) {
            this.form.currentPage -= 1;
            this.getListByField();
          } else {
            this.$nextTick(() => {
              this.setTableHeight(
                res.data.list ? res.data.list[0].dailyTimes.length : 0,
                res.data.list ? res.data.list.length : 0
              )
            })
          }

        } else {
          this.tableData = [];
          this.total = 0;
          this.$message.error(res.msg);
        }
        this.exportForm = { ...data };
      });
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.form.currentPage = page;
      this.getListByField();
    },
    // 当前条数改变
    onSizeChange (size) {
      this.form.pageSize = size;
      this.getListByField();
    },
    //获取字典值
    getDicts () {
      queryDictsByCodes({ parentCodes: "VETY,CPYS" }).then((res) => {
        if (res.code === 1000) {
          this.typeObj = formatDict(res.data.VETY);
          this.colorObj = formatDict(res.data.CPYS);
        }
      });
    },
    setTableHeight (Xlength, Ylength) {
      setTimeout(() => {
        if (Xlength > 3) {
          document.querySelector(
            '.location-statistics .el-table--scrollable-x .el-table__body-wrapper'
          ).style = `height:${this.tableHeight - 80}px`
        }
      }, 400)

      if (Ylength >= 10) {
        setTimeout(() => {
          document.querySelector(
            '.location-statistics .el-table .el-table__body-wrapper'
          ).style = `height:${this.tableHeight - 80}px`
        }, 600)
      }
    }
  },
  created () {
    this.getDicts();
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed () {
    window.removeEventListener("resize", this.computeHeight);
  },
};
</script>

<style lang="scss" scoped>
.location-statistics {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
}

.add {
  height: 100%;
  padding: 2vh 3vh;
}

// .theme-project-gps {
  .location-statistics {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
// }
</style>
